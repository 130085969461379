<template>
  <div id="team-profile">
    <NavBarFree v-if="!$route.query.isLanding" :custom="colorsConfig" />
    <TeamProfile
      use-local-loading
      :season-id="$route.query.season"
      :team-id="$route.query.team"
      :requests-token="user.token || token.free"
      :is-premium-user="!!user.premiumAccount"
      :is-golstats="!!user.golstats"
      @premium-disabled-clicked="onPremiumDisabledClicked"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapMutations, mapState } from 'vuex';
import token from '@/data/user/token';
import NavBarFree from '@/components/NavBars/NavBarFree';
import 'alets-fonts/css/fonts.css';
import store from '@/store';
import axios from 'axios';
import { Icon, Select, Checkbox, Loading, Field, Switch, Button, Collapse, Table, Modal, Radio } from 'buefy';
import TeamProfile from '@golstats/team-profile';
import VueObserveVisibility from 'vue-observe-visibility';
Vue.use(VueObserveVisibility);
Vue.use(Icon);
Vue.use(Select);
Vue.use(Checkbox);
Vue.use(Loading);
Vue.use(Field);
Vue.use(Switch);
Vue.use(Button);
Vue.use(Collapse);
Vue.use(Table);
Vue.use(Modal);
Vue.use(Radio);
Vue.use(TeamProfile, { store, axios });

export default {
  name: 'B2CTeamProfile',
  components: {
    NavBarFree,
  },
  data() {
    return {
      token,
      colorsConfig: {
        logo: 'https://az755631.vo.msecnd.net/content/shieldLogoPc.png',
        color: '#FFFFFF',
        headerBackground: '#242424',
        headerColor: '#FFFFFF',
        headerBorderColor: '##6EB913',
        header2Background: '#3b3b3b',
        header2Color: '#FFFFFF',
        subHeaderBackground: '#cce8b5',
        subHeaderColor: '#353535',
        type: 1,
        team_id: 0,
        tournaments: false,
        categories: 'all',
      },
    };
  },
  computed: {
    ...mapState('loginGeneral', ['user']),
  },
  mounted() {
    let colorsConfig = window.localStorage.getItem('colorsConfig');
    if (colorsConfig) {
      this.colorsConfig = JSON.parse(colorsConfig);
    }
  },
  methods: {
    ...mapMutations(['SET_PREMIUM_MESSAGE']),
    ...mapMutations('general', ['setSectionAnalytics']),
    onPremiumDisabledClicked() {
      this.setSectionAnalytics('Popup_AF_TeamProfile');
      this.SET_PREMIUM_MESSAGE(true);
    },
  },
};
</script>
<style lang="scss">
html > body {
  padding-top: 0 !important;
}
.is-fullheight-with-navbar-and-footer,
.is-fullheight-with-navbar-and-footer-mobile {
  height: 100%;
}
#team-profile {
  .has-background-primary {
    background-color: #4099ff !important;
  }

  .has-text-primary {
    color: #4099ff !important;
  }

  .switch input[type='checkbox'] + .check.is-primary-passive,
  .switch input[type='checkbox'] + .check:hover {
    background: #7dd421;
  }
}
</style>
